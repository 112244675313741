// const WEBURL = 'http://192.168.0.35:5000/api';
const WEBURL = 'https://admin.museemusical.shop/public/api';
//const WEBURL = 'http://127.0.0.1:8000/api';
window.API_URL = WEBURL;
window.AUTH_BG = 'https://cdn.pixabay.com/photo/2013/11/03/08/05/cheers-204742_960_720.jpg';
window.API_COLLECTION_SECTION_TWO_URL = WEBURL + '/get/product/collections/1';
window.API_COLLECTION_SECTION_THREE_URL = WEBURL + '/get/product/collections/1';
window.API_COLLECTION_SECTION_FOUR_URL = WEBURL + '/get/product/collections/1';
window.API_COLLECTION_SECTION_FIVE_URL = WEBURL + '/get/product/collections/1';
window.API_COLLECTION_SECTION_SIX_URL = WEBURL + '/get/product/collections/1';
window.API_COLLECTION_SECTION_SEVEN_URL = WEBURL + '/get/product/collections/1';
window.API_COLLECTION_SECTION_EIGHT_URL = WEBURL + '/get/product/collections/1';
window.API_GET_PRODUCT = WEBURL + '/get/products';
window.productLimit = 12;
window.CALENDLY_TOKEN = "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjc0NDU5NTcxLCJqdGkiOiIxNGM1MDZmMi0wYTYwLTRmZWEtODA4Yy1jNzk5ZDQ5NDE0ZGEiLCJ1c2VyX3V1aWQiOiJlNGM4OTQxMS04MTU0LTQyMzgtOTAyYS1lNzU4YjU5M2ZkNDkifQ.B1KpYF-dY_gZqZ1mDhb6k2R6RIWd4lOabtAGIeA9Vtbu-XJpkRnQTkpUtMWRCx99xR8Yx6Bf5Rg2ZlI8S-s_HQ"
window.LOGIN_SUCCESS_MSG = 'Login Successfull';